@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .fullscreen-enabled {
        overflow: auto;
    }

    .option {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1024px) {
        .min-h-theory{
            min-height: 670px !important;
        }
    }
}

.rule{
    @apply font-bold text-xl
}
.italic{
    @apply text-red-800
}