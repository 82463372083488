#scoreWindow{
    margin-top:-19px;
    position:relative;
}
#scoreWindow div{
    width:10px;
    height:20px;
    display:inline-block;
}
#pre1, #pre2, .pre{
    background-color:#E8D91E;
}
#five, #ten, .five{
    background-color:#16B409;
}
#four, #nine, .four{
    background-color:#75F22E;
}
#three, #eight, .three{
    background-color:#CFEF42;
}
#two, #seven, .two{
    background-color:#F8A910;
}
#one, #six, .one{
    background-color:#FE3500;
}

#flags{
    position:relative;
    width:auto;
    height:25px !important;
    color: red;
    display:block;
    padding:0 !important;
    margin-top:-26px;
}

.reviewflags{
    font-size: 25px;
    margin-top:-25px;
    height:25px;
    padding:0;
    display:block;
    position:relative;
}
.clipAnalysis{
    font-weight:700;
    padding-bottom:30px;
}
#score-analysis{
    width:100%;
    display:inline-block;
    border:1px solid #000;
    position:relative;
}
#car{
    width:2px;
    height:35px;
    background-color:red;
    position:absolute;
    display:block;
    margin-left:-1px;
    padding-top:80px;
}
#car span{
    position:absolute;
    width: auto !important;
    white-space: nowrap;
    transform: translateX(-50%);
}
#car.low span {
    transform: none !important;
}
#score-0, #score-1, #score-2, #score-3, #score-4, #score-5{
    width:16.666666666667%;
    height:80px;
    display:inline-block;
    margin:0;
    padding:0;
}

#anticheat{
    color:#DC0201;
    font-size:1.5em;
    letter-spacing:2px;
}

video{
    margin-left: auto !important;
    margin-right: auto !important;
}